import { render, staticRenderFns } from "./Ic5gOtafView.vue?vue&type=template&id=321f7f1c&scoped=true"
import script from "./Ic5gOtafView.vue?vue&type=script&setup=true&lang=js"
export * from "./Ic5gOtafView.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "321f7f1c",
  null
  
)

export default component.exports